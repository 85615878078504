import _defaultOptions from "./defaultOptions";
import _spawnWorker from "./spawnWorker";
import _terminateWorker from "./terminateWorker";
import _onMessage from "./onMessage";
import _send from "./send";
import _loadImage from "./loadImage";
var exports = {};
/**
 *
 * Tesseract Worker adapter for browser
 *
 * @fileoverview Tesseract Worker adapter for browser
 * @author Kevin Kwok <antimatter15@gmail.com>
 * @author Guillermo Webster <gui@mit.edu>
 * @author Jerome Wu <jeromewus@gmail.com>
 */
const defaultOptions = _defaultOptions;
const spawnWorker = _spawnWorker;
const terminateWorker = _terminateWorker;
const onMessage = _onMessage;
const send = _send;
const loadImage = _loadImage;
exports = {
  defaultOptions,
  spawnWorker,
  terminateWorker,
  onMessage,
  send,
  loadImage
};
export default exports;
const _defaultOptions2 = exports.defaultOptions,
  _spawnWorker2 = exports.spawnWorker,
  _terminateWorker2 = exports.terminateWorker,
  _onMessage2 = exports.onMessage,
  _send2 = exports.send,
  _loadImage2 = exports.loadImage;
export { _defaultOptions2 as defaultOptions, _spawnWorker2 as spawnWorker, _terminateWorker2 as terminateWorker, _onMessage2 as onMessage, _send2 as send, _loadImage2 as loadImage };