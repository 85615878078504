var exports = {};
/**
 * terminateWorker
 *
 * @name terminateWorker
 * @function terminate worker
 * @access public
 */
exports = worker => {
  worker.terminate();
};
export default exports;